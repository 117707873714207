import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'
import React from 'react'

import { useSendEmailSendgridCancelPlanMutation } from '../../api/api'

const CancelPlanConfirmationModal = ({ show, onClose, cancelPlan, planName }) => {
    const user = useSelector((state) => state.app.user)
    const subscription = useSelector((state) => state.app.subscription)

    const { t } = useTranslation()

    const [sendEmailSendgridCancelPlan] = useSendEmailSendgridCancelPlanMutation()

    const handleCancelPlan = () => {
        cancelPlan()
        onClose()
        sendEmailSendgridCancelPlan({
            plan_name: planName,
        })
        window.gtag('event', 'user_cancel_plan_from_settings', {
            user: user.uid,
            payment_method: subscription?.payment_method === 'paypal' ? 'paypal' : 'stripe',
        })
    }

    return (
        <Modal
            show={show}
            size="md"
            popup="false"
            centered
            position='center'
            onClose={onClose}
            className='modal-please-signup'
        >
            <Modal.Body className='confirm-archive-modal-body'>
                <h5 className='confrim-modal-title'>{t("areYouSureYouWantToCancelYourPlan")}</h5>
                <div className='confirm-modal-actions-block'>
                    <button onClick={onClose} className='confirm-modal-action-cancel'>{t("keepPlan")}</button>
                    <button onClick={handleCancelPlan} className='confirm-modal-action-delete'>{t("yesCancelPlan")}</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default CancelPlanConfirmationModal
import { Link, useParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
    useCreateAlternativeSubscriptionMutation,
    useCreateSubscriptionMutation
} from '../../api/api';
import {
    STRIPE_FIREBASE_EMAIL_LANGUAGE_TRANSLATIONS_KEYS,
    LANGUAGE_SHORT_VERSION_TO_CURRENCY_CODE,
    PRICE_SIGN_TRANSLATIONS
} from '../../helpers/translation-constants';
import useAccountNewPlans from '../../hooks/useAccountNewPlans';
import useGenerateUrl from '../../hooks/useGenerateUrl';
import { accountPlans } from '../../helpers/constants';
import i18n from '../../i18n/config';

import StripeForm from '../../components/StripeForm/StripeForm';

import checkoutCancelAnytime from '../../assets/icons/checkout-cancel-anytime.svg'
import checkoutCreditCard from '../../assets/icons/cehckout-credit-card.svg'
import checkoutMoneyBack from '../../assets/icons/checkout-money-back.svg'
import poweredByStripe from '../../assets/icons/powered-by-stripe.png'
import checkoutPaypal from '../../assets/icons/checkout-paypal.svg'

const CheckoutForm = () => {
    const stripePromise = useMemo(() => {
        return loadStripe("pk_live_51NjCF0G2gC76wG4CuacboIvtDia4Pv87gp8SF7Xi2ue6gvD6kdr4BKpb1btzbgfW9d2aTXvMzSIrQQzz8PppRKDW00YBZRKLCH", {
            locale: STRIPE_FIREBASE_EMAIL_LANGUAGE_TRANSLATIONS_KEYS[i18n.language],
        });
    }, [i18n.language]);

    const subscription = useSelector((state) => state.app.subscription)
    const [secret, setSecret] = useState('')
    const [planPrice, setPlanPrice] = useState(0)
    const [selectedPlan, setSelectedPlan] = useState({})
    const { secret: priceId, plan } = useParams()

    const clientSecret = secret ? secret : ''

    const { t } = useTranslation()
    const generateUrl = useGenerateUrl()
    const { accountNewPlans } = useAccountNewPlans()

    const [createSubscription] = useCreateSubscriptionMutation()
    const [createAlternativeSubscription] = useCreateAlternativeSubscriptionMutation()

    const appearance = {
        theme: 'none',
        variables: {
            spacingUnit: '4px',
        },
        rules: {
            '.Tab': {
                border: '1px solid #E2E8F0',
                boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',
            },

            '.Input': {
                border: '1px solid #E2E8F0',
                backgroundColor: '#F7FAFE',
                padding: '14px 16px',
                outline: 'none',
                marginBottom: '3px'
            },

            '.Label': {
                fontSize: '12px',
                fontWeight: '700',
                lineHeight: '18px',
                color: '#334155',
                fontVariant: 'normal',
                marginBottom: '3px'
            },

            '.Tab:hover': {
                color: 'var(--colorText)',
            },

            '.Tab--selected': {
                borderColor: '#E2E8F0',
                boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)',
            },

            '.Input--invalid': {
                boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)',
            },

            '.Input::placeholder': {
                color: '#7D8898',
                fontSize: '16px',
            },

            '.TermsText': {
                color: '#7D8898',
                fontSize: '12px',
            }
        }
    };

    const options = {
        clientSecret,
        appearance,
    };

    useEffect(() => {
        if (plan) {
            setSelectedPlan(accountNewPlans.find((el) => el.shortName === plan))
        }
    }, [plan, accountNewPlans])

    useEffect(() => {
        (async () => {
            let res;

            if (priceId.includes(',')) {
                const [price_id1, price_id2] = priceId.split(',')

                res = await createAlternativeSubscription({
                    price_id1,
                    price_id2,
                    currency: LANGUAGE_SHORT_VERSION_TO_CURRENCY_CODE[i18n.language] ?? 'USD',
                    country: i18n.language
                })
            } else if (priceId === 'price_1OFbqAG2gC76wG4CDgASNzV8') {
                res = await createAlternativeSubscription({
                    price_id1: priceId,
                    currency: LANGUAGE_SHORT_VERSION_TO_CURRENCY_CODE[i18n.language] ?? 'USD',
                    country: i18n.language
                })
            } else {
                res = await createSubscription({
                    priceId,
                    currency: LANGUAGE_SHORT_VERSION_TO_CURRENCY_CODE[i18n.language] ?? 'USD',
                    country: i18n.language
                })
            }

            setSecret(res?.data?.client_secret)
        })()
    }, [createSubscription, createAlternativeSubscription, priceId])

    useEffect(() => {
        let foundPlanPrice;

        if (subscription?.new_flow) {
            foundPlanPrice = accountNewPlans.find((el) => el.shortName === plan)?.yearlyPrice[i18n.language]
                ?? accountNewPlans.find((el) => el.shortName === plan)?.yearlyPrice['en']
        } else {
            foundPlanPrice = accountPlans.find((el) => el.shortName === plan)?.yearlyPrice
        }

        if (foundPlanPrice) {
            setPlanPrice(foundPlanPrice)
        }
    }, [plan])

    return (
        <div style={{ paddingTop: '0px' }} className='purple-corners-block-wrapper'>
            {clientSecret ? (
                <Elements options={options} stripe={stripePromise}>
                    <StripeForm priceId={priceId} planName={plan} />
                </Elements>
            ) : (
                <div className="checkout-page-loader-wrapper">
                    <div className="checkout-page-loader pb-4">
                        <div className="checkout-body">
                            <div className="checkout-field-block">
                                <div className='checkout-new-title-powered'>
                                    <div className="checkout-body-header">{t("secureCheckout")}</div>
                                    <img width={120} src={poweredByStripe} alt="" />
                                </div>
                                <div className="checkout-card-field">
                                    <span className="checkout-new-payment-method-title">
                                        {t("paymentMethod")}
                                    </span>
                                    <div className='select-payments-block-wrapper'>
                                        <div className={`selecet-payment-method-element active`}>
                                            <img src={checkoutCreditCard} alt="" />
                                            <span className='select-payment-method-name'>{t("creditCard")}</span>
                                        </div>
                                        <div className={`selecet-payment-method-element`}>
                                            <img src={checkoutPaypal} alt="" />
                                            <span className='select-payment-method-name'>PayPal</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='preparing-payment-information-block'>
                                    <svg className='stripe-loader' viewBox="25 25 50 50">
                                        <circle r="20" cy="50" cx="50"></circle>
                                    </svg>
                                    {t("preparingPaymentInformation")}
                                </div>
                            </div>
                            <div className="checkout-body-right">
                                <div className='checkout-guarantee-block disable-on-desktop'>
                                    <span style={{ color: '#7D8898' }} className='new-flow-checkout-text'>{t("byClickingPurchaseYouAgreeToBeCharged")} {PRICE_SIGN_TRANSLATIONS[i18n.language] ?? '$'}{planPrice} {t("nowAndAcceptOur")} <Link className='new-flow-checkout-text-link' to={generateUrl('/terms-of-service')}>{t("termsOfUse")}</Link> {t("and")} <Link className='new-flow-checkout-text-link' to={generateUrl("/privacy-policy")}>{t("privacyPolicyWithDot")}</Link> {t("yourPaymentWillAppearAsQRCodeDeveloperOnYourCard")} {plan !== 'Yearly' ? t("after14daysYouWillBeBilledEveryMonth") : ""} {t("youCanCancelAnytime")} </span>
                                </div>
                                <div className='checkout-guarantee-underline disable-on-desktop'></div>
                                <div className='checkout-guarantee-block'>
                                    <div className='checkout-guarantee-icon-wrapper'>
                                        <img src={checkoutMoneyBack} alt="" />
                                    </div>
                                    <span className='checkout-guarantee-block-title'>{t("moneyBackGuarantee")}</span>
                                    <span className='checkout-guarantee-block-text'>{t("ifYouAreNotFullySatisfiedWithinThe14day")}</span>
                                </div>
                                <div className='checkout-guarantee-underline'></div>
                                <div className='checkout-guarantee-block'>
                                    <div className='checkout-guarantee-icon-wrapper'>
                                        <img src={checkoutCancelAnytime} alt="" />
                                    </div>
                                    <span className='checkout-guarantee-block-title'>{t("cancelViaYOurOnlineAccount")}</span>
                                    <span className='checkout-guarantee-block-text'>{t("youMayCancelAnytimeFromDashboard")}</span>
                                </div>
                                <div style={{ marginBottom: '116px' }} className='checkout-guarantee-underline disable-on-desktop'></div>
                            </div>
                            <div className='change-selected-plan-block-mobile'>
                                <div className='change-selected-plan-block-info'>
                                    <span className='change-selected-plan-block-title'>{t("selectedPlan")}</span>
                                    <span className='change-selected-plan-block-name'>{selectedPlan?.name}</span>
                                </div>
                                <span className='change-selected-plan-block-change-btn'>{t("changePlan")}</span>
                            </div>
                            <div className='checkout-new-purchase-block'>
                                <div className='checkout-new-due-total-block w-full'>
                                    <span className='checkout-new-due-total-title'>{t("dueToday")}</span>
                                    <span className='checkout-new-due-total-price'>
                                        {PRICE_SIGN_TRANSLATIONS[i18n.language] ?? '$'}{planPrice}
                                    </span>
                                </div>
                                <button
                                    id="submit"
                                    className="checkout-new-purchase-button relative"
                                >
                                    {t("purchase")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default CheckoutForm